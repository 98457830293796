//
//
//
//

import Exception from './exception.vue';

export default {
  name: 'Page404',
  components: {
    Exception,
  },
  data() {
    return {
      model: {
        // eslint-disable-next-line global-require
        src: require('@/assets/exception-images/exception_404.svg'),
        title: 'Lost your way?',
        desc: 'Sorry, we can\'t find that page. You\'ll find loads to explore on the home page.',
      },
    };
  },
};
